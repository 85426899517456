import { FETCH_FAMILION_HOME, FETCH_INVITACIONES_RECIBIDAS, FETCH_INVITACIONES_ENVIADAS, ACTUALIZAR_INVITACION, FETCH_INTEGRANTES_FAMILION, DELETE_INTEGRANTE_FAMILION, FETCH_TyC, FETCH_FAMILION_USERS, RESETEAR_ELIMINADOFAMILION } from "../../types";

const familionReducer = (state, action) => {
    switch (action.type) {

        case FETCH_FAMILION_HOME:
            return {
                familiON: action.payload
            }

        case FETCH_INVITACIONES_RECIBIDAS:
            return {
                ...state,
                invitacionesRecibidas: action.payload
            };

        case FETCH_INVITACIONES_ENVIADAS:
            return {
                ...state,
                invitacionesEnviadas: action.payload
            };

        
        case FETCH_INTEGRANTES_FAMILION:
            return {
                ...state,
                integrantes: action.payload
            };

        case FETCH_TyC:
            return {
                ...state,
                tyc:action.payload
            }
        

        case DELETE_INTEGRANTE_FAMILION:
            return {
                    ...state,
                    integranteEliminadoStatus: action.payload
                };

        

        case ACTUALIZAR_INVITACION:
            return {
                ...state,
                actualizarInvitaciones: action.payload
            };

            
        case FETCH_FAMILION_USERS:
            return {
                ...state,
                integrantes: action.payload
            };
        case RESETEAR_ELIMINADOFAMILION:
            return {
                ...state,
                familiON: {
                    ...state.familiON,
                    eliminadoDeFamilion: false
                },
            }
        default:
            return state;

    }
}

export default familionReducer;