import react, { useReducer } from "react";
import concursosContext from './concursosContext';
import concursosReducer from "./concursosReducer";
import clienteAxios from "../../config/axios";
import { PINTAR_REPUESTA_CONCURSO, OBTENER_CONCURSO, MENSAJE_ERROR, MODAL_CONCURSOS_RESPUESTAS, OBTENER_FOTOS_PARA_VOTAR, OBTENER_CONCURSO_PREGUNTAS, RESETEAR_CARGANDO, CONCURSOS_BANNER_ITEM, CONCURSOS_GANADORES, RESETEAR_MENSAJE } from '../../types/index';
import axios from "axios";

const ConcursosState = props => {

    const initialState = {
        listadoGanadores: null,
        pintarRespuesta: {
            index: null,
            correcta: null,
            monedas: null,
            repetir: null
        },
        mostrarModalConcursos: {
            mostrar: null,
            gano: null,
            mensaje: null
        },
        mensajeConcursos: null,
        cargandoConcursos: true,
        concursoCompleto: {},
        concurso: {},
        fotosParaVotar: {},
        concursoBanner: [],
        redesSociales: {
            displayNames: true,
            config: [
                {
                    redSocial: "Facebook",
                    socialShareUrl: '',
                    icon: "/img/iconos-redes-sociales/facebook.svg",
                    // socialShareType: 'send',
                    // socialShareVia: null,
                    // socialShareVia: '443746854309562',
                    // socialShareRedirectUri: ''
                },
                {
                    redSocial: "Twitter",
                    socialShareUrl: '',
                    icon: "/img/iconos-redes-sociales/twitter.svg"
                },
                {
                    redSocial: "Whats App",
                    socialShareUrl: '',
                    icon: "/img/iconos-redes-sociales/whatsapp.svg"
                },
                {
                    redSocial: "Email",
                    socialShareBody: '',
                    socialShareSubject: "Concurso de la App Axion Energy",
                    icon: "/img/iconos-redes-sociales/email.svg"
                },
                {
                    redSocial: "Telegram",
                    socialShareUrl: '',
                    icon: "/img/iconos-redes-sociales/telegram.svg"
                },
                {
                    redSocial: "Copy",
                    socialShareUrl: '',
                    icon: "/img/iconos-redes-sociales/copy.svg"
                }
            ]
        }
    }

    const [state, dispatch] = useReducer(concursosReducer, initialState);

    const obtenerConcursobanner = async () => {

        resetearCargando();
        await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getconcursobanner`)
            .then(r => {

                if (r.data?.statusCode == 206) {
                    enviarError(r);
                }
                else {
                    dispatch({
                        type: CONCURSOS_BANNER_ITEM,
                        payload: r.data.response,
                    })
                }
            })
            .catch(e => {
                enviarError(e);
            });

    }

    const obtenerConcursoDetalles = async (id) => {
        resetearCargando();

        await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getconcursodetallesbyid/${id}`)
            .then(r => {
                if (r.data?.statusCode == 206) {
                    enviarError(r);
                }
                else {
                    var fechaHasta = r.data.fechaHasta;
                    fechaHasta = r.data.fechaHasta.split("/");
                    var anio = fechaHasta[2].split(" ");
                    r.data.fechaHasta = fechaHasta[1] + "/" + fechaHasta[0] + "/" + anio[0];
                    var fechaDesde = r.data.fechaDesde;
                    fechaDesde = r.data.fechaDesde.split("/");
                    anio = fechaDesde[2].split(" ");
                    r.data.fechaDesde = fechaDesde[1] + "/" + fechaDesde[0] + "/" + anio[0];
                    dispatch({
                        type: OBTENER_CONCURSO,
                        payload: r.data
                    });

                    const data = r.data;

                    window.dataLayer.push({
                        "event": "view_concurso",
                        "category": `${data.titulo}/${data.id}`,
                        "label": data.tipo,
                        "value": data.concursanteEstado === 1 ? true : false
                    });
                }
            })
            .catch(e => {
                enviarError(e);
            })
    }

    const obtenerConcurso = async (id) => {
        dispatch({ type: MODAL_CONCURSOS_RESPUESTAS, payload: { mostrar: false, gano: null, mensaje: null } });
        dispatch({ type: PINTAR_REPUESTA_CONCURSO, payload: { index: null, correcta: null, monedas: null, repetir: null } })
        await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getconcursobyid/${id}`)
            .then(r => {
                if (r.data?.statusCode == 206) {
                    enviarError(r);
                }
                else {
                    dispatch({ type: OBTENER_CONCURSO_PREGUNTAS, payload: r.data });
                    if (r.data.tipo == "Multiple Choice") actualizarPregunta(r.data);
                    if (r.data.tipo == "Desafío Foto") obtenerFotosVotar();
                }
            })
            .catch(e => {
                enviarError(e);
            })
    }

    const guardarRespuestaLosPrimerosX = async (datos) => {

        await clienteAxios.post(`${process.env.REACT_APP_API_CONTEXT_APID365}/concursanterespuestaapreciacion`, datos)
            .then(r => {
                if (r.data?.statusCode == 206) {
                    const alerta = {
                        msg: r.data.message !== undefined ? r.data.message : "Hubo un problema! No se pudo guardar su respuesta",
                        categoria: r.data.type !== undefined ? r.data.type : "error",
                    }
                    dispatch({ type: MENSAJE_ERROR, payload: alerta });
                    return false;
                }
                else {
                    dispatch({
                        type: MODAL_CONCURSOS_RESPUESTAS, payload: {
                            mostrar: true,
                            gano: r.data.response.gano,
                            mensaje: r.data.response.mensaje
                        }
                    });
                    return true;
                }
            })
            .catch(e => {
                const alerta = {
                    msg: e.data.message !== undefined ? e.data.message : "Hubo un problema! No se pudo guardar su respuesta",
                    categoria: e.data.type !== undefined ? e.data.type : "error",
                }
                dispatch({ type: MENSAJE_ERROR, payload: alerta });
                return false;
            })
    }

    const guardarRespuestaDesafioFoto = async (datos) => {
        await clienteAxios.post(`${process.env.REACT_APP_API_CONTEXT_APID365}/concursanterespuestadesafiodefotos`, datos)
            .then(r => {
                if (r.data?.statusCode == 206) {
                    const alerta = {
                        msg: r.data.message !== undefined ? r.data.message : "Hubo un problema! No se pudo guardar su respuesta",
                        categoria: r.data.type !== undefined ? r.data.type : "error",
                    }
                    dispatch({ type: MENSAJE_ERROR, payload: alerta });
                    return false;
                }
                else {
                    dispatch({
                        type: MODAL_CONCURSOS_RESPUESTAS, payload: {
                            mostrar: true,
                            gano: r.data.response.gano,
                            mensaje: r.data.response.mensaje
                        }
                    });
                    return true;
                }
            })
            .catch(error => {
                const alerta = {
                    msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
                    categoria: error !== undefined ? error.data.type : "error",
                }
                dispatch({ type: MENSAJE_ERROR, payload: alerta });
                return false;
            })
        return false;
    }

    const actualizarPregunta = (concursoActual) => {
        var tam = concursoActual.preguntas.length;
        var concurso = {};
        for (var i = 0; i <= tam - 1; i++) {
            if (concursoActual.preguntas[i].respondida === false) {
                concurso = {
                    tipo: concursoActual.tipo,
                    idConcurso: concursoActual.id,
                    contenido: {
                        pregunta: {
                            id: concursoActual.preguntas[i].id,
                            tiempoRespuesta: concursoActual.preguntas[i].tiempoRespuesta,
                            pregunta: concursoActual.preguntas[i].pregunta,
                            imagen: concursoActual.preguntas[i].imagen,
                            respondida: concursoActual.preguntas[i].respondida,
                            cantidad: concursoActual.preguntas.length,
                            numero: i + 1,
                            respuesta: concursoActual.preguntas[i].respuestas,
                            titulo: concursoActual.preguntas[i]?.titulo
                        }
                    }
                }
                dispatch({ type: OBTENER_CONCURSO, payload: concurso });
                break;
            }
        }
    }

    const guardarRespuestaMultipleChoice = async (indexRespuesta, indexPregunta, cantidadPreguntas) => {
        var primerPregunta = false;
        for (var i = 0; i <= cantidadPreguntas - 1; i++) {
            if (indexPregunta === i) {
                state.concursoCompleto.preguntas[i].respondida = true;
                if (i == 0) primerPregunta = true;
            }
        }
        var opcion = null;
        if (indexRespuesta != null) opcion = state.concursoCompleto.preguntas[indexPregunta].respuestas[indexRespuesta].id;
        var datos = {
            ConcursanteRespuestaOpcionId: opcion,
            ConcursoGUID: state.concursoCompleto.id,
            ConcursoPreguntaGUID: state.concursoCompleto.preguntas[indexPregunta].id,
            isNuevoIntento: primerPregunta
        }

        await clienteAxios.post(`${process.env.REACT_APP_API_CONTEXT_APID365}/concursanterespuestamultiplechoice`, datos)
            .then(r => {
                if (r.data?.statusCode == 206) {
                    const alerta = {
                        msg: r.data.message !== undefined ? r.data.message : "Hubo un problema! No se pudo guardar su respuesta",
                        categoria: r.data.type !== undefined ? r.data.type : "error",
                    }
                    dispatch({ type: MENSAJE_ERROR, payload: alerta });
                }
                else {
                    if (indexRespuesta != null) {
                        dispatch({
                            type: PINTAR_REPUESTA_CONCURSO, payload: {
                                index: indexRespuesta,
                                correcta: r.data.response.correcta,
                                monedas: null,
                                repetir: null,
                            }
                        })
                        setTimeout(() => {
                            if (indexPregunta + 1 < cantidadPreguntas) actualizarPregunta(state.concursoCompleto);
                            if (state.concursoCompleto.preguntas[cantidadPreguntas - 1].respondida === true) {
                                dispatch({
                                    type: MODAL_CONCURSOS_RESPUESTAS, payload: {
                                        mostrar: true,
                                        gano: r.data.response.gano,
                                        mensaje: r.data.response.mensaje,
                                        repetir: r.data.response.repetir,
                                        monedas: r.data.response.monedas
                                    }
                                });
                            }
                        }, 1000);
                    }
                    else {
                        if (indexPregunta + 1 < cantidadPreguntas) actualizarPregunta(state.concursoCompleto);
                        if (state.concursoCompleto.preguntas[cantidadPreguntas - 1].respondida === true) {
                            dispatch({
                                type: MODAL_CONCURSOS_RESPUESTAS, payload: {
                                    mostrar: true,
                                    gano: r.data.response.gano,
                                    mensaje: r.data.response.mensaje,
                                    repetir: r.data.response.repetir,
                                    monedas: r.data.response.monedas
                                }
                            });
                        }
                    }
                }

            })
            .catch(error => {
                var alerta = {
                    msg: error !== undefined ? error.data.message : "Hubo un problema! No se pudo guardar su respuesta",
                    categoria: error !== undefined ? error.data.type : "error",
                }
                dispatch({ type: MENSAJE_ERROR, payload: alerta });
            })
    }

    const desconctarMonedas = async (monedasDescontar) => {
        await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/descontarmonedas/${monedasDescontar}`)
            .then(r => {
                if (r.data?.statusCode == 206) {
                    enviarError(r);
                }
                else {
                    if (state.concursoCompleto.intentarDevuelta) {
                        for (var i = 0; i < state.concursoCompleto.preguntas.length; i++) {
                            state.concursoCompleto.preguntas[i].respondida = false;
                        }
                    }
                    actualizarPregunta(state.concursoCompleto);
                }
            })
            .catch(e => {
                enviarError(e);
            });
    }

    const obtenerGanadoresConcursos = async (idConcurso) => {
        await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getganadoresconcursos/${idConcurso}`)
            .then(r => {
                if (r.data?.statusCode == 206) {
                    enviarError(r);
                }
                else {
                    dispatch({
                        type: CONCURSOS_GANADORES,
                        payload: r.data.response
                    });
                }
            })
            .catch(e => {
                enviarError(e);
            })
    }

    const enviarError = (err) => {
        var error = null;
        if (err.response !== undefined) error = err.response;
        if (err.data !== undefined) error = err;

        const alerta = {
            msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
            categoria: error !== undefined ? error.data.type : "error",
        }
        dispatch({
            type: MENSAJE_ERROR,
            payload: alerta,
        })
    }
    const resetearCargando = () => {
        dispatch({
            type: RESETEAR_CARGANDO,
        })
    }

    const resetearmensajeConcursos = () => {
        dispatch({
            type: RESETEAR_MENSAJE,
        })
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////// OLD
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    /* DESAFIO FOTO /////////////////////////////////////////////////////////////*/

    const obtenerFotosVotar = async () => {
        var imagenesVotar =
        {
            voto: "v23hb234b234b324b234b23",
            candidatos: [
                {
                    id: "k5436b34k5bjk6b4k5hb63c",
                    nombre: "Carlos Rodriguez",
                    cantidadVotos: 12,
                    imagen: ""
                },
                {
                    id: "v23hb234b234b324b234b23",
                    nombre: "Saul Gomez",
                    cantidadVotos: 16,
                    imagen: ""
                },
                {
                    id: "k32432jiu4231i4b12iklk3",
                    nombre: "Ricardo Montaner",
                    cantidadVotos: 1,
                    imagen: ""
                }
            ]
        };
        dispatch({ type: OBTENER_FOTOS_PARA_VOTAR, payload: imagenesVotar });
    }

    const votarImagen = async (datos) => {

        await axios.post(`${datos}`)
            .then(r => {

            })
            .catch(e => {

            })
    }

    return (
        <concursosContext.Provider
            value={{
                mensajeConcursos: state.mensajeConcursos,
                cargandoConcursos: state.cargandoConcursos,
                concurso: state.concurso,
                concursoCompleto: state.concursoCompleto,
                mostrarModalConcursos: state.mostrarModalConcursos,
                fotosParaVotar: state.fotosParaVotar,
                redesSociales: state.redesSociales,
                concursoBanner: state.concursoBanner,
                pintarRespuesta: state.pintarRespuesta,
                listadoGanadores: state.listadoGanadores,
                obtenerConcursobanner,
                obtenerConcursoDetalles,
                obtenerConcurso,
                guardarRespuestaLosPrimerosX,
                guardarRespuestaMultipleChoice,
                guardarRespuestaDesafioFoto,
                obtenerFotosVotar,
                votarImagen,
                desconctarMonedas,
                obtenerGanadoresConcursos,
                resetearmensajeConcursos
            }}
        >
            {props.children}
        </concursosContext.Provider>
    )
}

export default ConcursosState;