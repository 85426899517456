import React, { useReducer } from "react";
import clienteAxios from "../../config/axios";
import { MENSAJE_ERROR, RESETEAR_CARGANDO, RESETEAR_MENSAJE, GET_BENEFICIOS_COMUNIDADES } from "../../types";
import comunidadesContext from "./comunidadesContext";
import comunidadesReducer from "./comunidadesReducer";

const ComunidadesState = props => {
    const initialState = {
        mensajeComunidades: null,
        cargandoComunidades: false,
        beneficiosComunidades:{}
    }

    const [state, dispatch] = useReducer(comunidadesReducer, initialState);


    const ObtenerBeneficiosComunidades = async() => {
        resetearCargando();
        await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_BENEFICIOS}/getbeneficioscomunidades`)
        .then((r)=>{
            if(r.data.response.length===0){
                r.data.message="Lo sentimos! Este beneficio no está disponible, explora otras opciones que ON tiene para vos!";
                enviarError(r);
            } 
            dispatch({type:GET_BENEFICIOS_COMUNIDADES,payload:r.data.response})
        })
        .catch((e)=>{
            enviarError(e);
        })
    }

    const enviarError = (err) => {
        var error=null;
        if(err.response!==undefined) error = err.response.data;
        if(err.data!==undefined) error = err.data;

        const alerta = {
            msg: error !== undefined ? error?.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
            categoria: error !== undefined ? error?.type : "error",
        }
        dispatch({
            type: MENSAJE_ERROR,
            payload: alerta,
        })
    }

    const resetearMensajeComunidades = () => {
        dispatch({
            type: RESETEAR_MENSAJE,
        })
    }

    const resetearCargando = () => {
        dispatch({
            type: RESETEAR_CARGANDO,
        })
    }

    return (
        <comunidadesContext.Provider
            value={{
                beneficiosComunidades: state.beneficiosComunidades,
                mensajeComunidades: state.mensajeComunidades,
                cargandoComunidades: state.cargandoComunidades,
                resetearMensajeComunidades,
                resetearCargando,
                ObtenerBeneficiosComunidades
            }}> 
            {props.children}
        </comunidadesContext.Provider>
    )
}

export default ComunidadesState;


