import React, { useReducer, useEffect } from "react";
import clienteAxios from "../../config/axios";
import { MENSAJE_ERROR, OBTENER_BENEFICIOS, OBTENER_ONSTREAM, RESETEAR_CARGANDO, OBTENER_ONSTREAM_CATEGORIA } from "../../types";
import onstreamContext from "./onstreamContext";
import onstreamReducer from "./onstreamReducer";

const ONStreamState = (props) => {
  const initialState = {
    onstream: null,
    beneficios: null,
    cargando: true,
    categoria: null
  };

  const [state, dispatch] = useReducer(onstreamReducer, initialState);

  useEffect(() => {
    const interceptor = clienteAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalConfig = error.config;
        if (error.response) {
          if (error.response.status === 401) {
            window.location.replace("/");
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      clienteAxios.interceptors.response.eject(interceptor);
    };
  }, []);

  const obtenerONStreams = async () => {
    try {
      const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getonstream`)
      if (respuesta.data?.statusCode === 206) {
        enviarError(respuesta);
      }
      else {
        dispatch({
          type: OBTENER_ONSTREAM,
          payload: respuesta.data.response,
        })
      }

    } catch (err) {
      enviarError(err);
    }
  }
  
  const obtenerBeneficios = async () => {
    try {
      const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_BENEFICIOS}/getbeneficiosonstream`)
      if (respuesta.data?.statusCode === 206) {
        enviarError(respuesta);
      }
      else {
        dispatch({
          type: OBTENER_BENEFICIOS,
          payload: respuesta.data.response,
        })
      }

    } catch (err) {
      enviarError(err);
    }
  }

  const obtenerONStreamsPorCategoria = async (id) => {
    try {

      const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getonstream?id=${id}`);
      if (respuesta.data?.statusCode === 205 || respuesta.data?.statusCode === 206) {
        enviarError(respuesta);
      }
      else {
        dispatch({
          type: OBTENER_ONSTREAM_CATEGORIA,
          payload: respuesta.data.response,
        })
      }
    } catch (err) {
      enviarError(err);
    }
  }

  const enviarError = (err) => {
    var error = null;
    if (err.response !== undefined) error = err.response;
    if (err.data !== undefined) error = err;

    const alerta = {
      msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
      categoria: error !== undefined ? error.data.type : "error",
    }
    dispatch({
      type: MENSAJE_ERROR,
      payload: alerta,
    })
  }


  const resetearCargando = () => {
    dispatch({
      type: RESETEAR_CARGANDO,
    })
  }


  return (
    <onstreamContext.Provider
      value={{
        onstream: state.onstream,
        beneficios: state.beneficios,
        cargando: state.cargando,
        categoria: state.categoria,
        obtenerONStreams,
        resetearCargando,
        obtenerBeneficios,
        obtenerONStreamsPorCategoria
      }}
    >
      {props.children}
    </onstreamContext.Provider>
  );
};

export default ONStreamState;