import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import usuarioContext from '../../context/usuario/usuarioContext'
import familionContext from '../../context/familion/familionContext';
import CardInvitacionesFamilion from './CardInvitacionesFamilion';

const ContentInvitacionesEnviadas = ({ svgColorsFill }) => {

    const { obtenerNivel, contacto } = useContext(usuarioContext);
    const { invitacionesEnviadas, getInvitacionesEnviadas } = useContext(familionContext);

    useEffect(() => {
        getInvitacionesEnviadas();
        obtenerNivel()
    }, []);


    const invitacionesPendientesFiltradas = invitacionesEnviadas?.filter(invitacion => invitacion.aceptada === false);

    return (
        <div className="row justify-content-center">
            <div className="w-100 col-md-10">
                <p style={{
                    fontFamily: 'Encode Sans',
                    fontSize: '16px',
                    fontWeight: 500,
                    margin: '10px',
                    marginBottom: '20px',
                    marginLeft: '20px',
                    lineHeight: '20px',
                    textAlign: 'left',
                    color: '#0C1561',
                    height: '20px',
                    gap: '0px',
                }}>
                    Invitaciones enviadas
                </p>
                <hr style={{ width: '96%', marginLeft: '2%' }} />
                <div className="d-flex flex-column gap-1" style={{ padding: '30px' }}>
                    {invitacionesPendientesFiltradas && invitacionesPendientesFiltradas?.length > 0 ? (
                        invitacionesPendientesFiltradas.map((invitacion, index) => (
                            <CardInvitacionesFamilion
                                key={index}
                                invitacion={invitacion}
                                svgColorsFill={svgColorsFill}
                                index={index}
                            />
                        ))
                    ) : (
                        <div className="container d-flex flex-column justify-content-center align-items-center" style={{
                            maxWidth: '800px',
                            height: '200px',
                            margin: '0 auto',
                            textAlign: 'center',
                            border: 'none',
                            boxShadow: 'none',
                            padding: '20px'
                        }}>
                            <p style={{
                                color: '#8F8E8E',
                                fontFamily: 'Encode Sans',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '17.5px',
                            }}>
                                No hay solicitudes enviadas
                            </p>
                            {!contacto?.familiON?.tieneGrupoFamiliar ? (
                                <Link to={"/familion/invitar"} className="btn btn-azul fs-16-bold mt-3 w-100 w-md-auto">
                                    Crear Mi FamiliON
                                </Link>
                            ) : null}
                        </div>

                    )}
                </div>
            </div>
        </div>
    );
};

export default ContentInvitacionesEnviadas;