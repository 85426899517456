import React from 'react';

const CardSolicitudesFamilion = ({ invitacion, svgColorsFill, index, onAceptar, onEliminar }) => {
    return (
        <div className=" d-flex flex-row justify-content-center  shadow-lg mb-3 align-items-center" style={{ height: '124px', borderRadius: '16px', width: '100%', margin: 'auto' }}>
            <div className="d-flex flex-row justify-content-center align-items-center px-2" style={{ width: '86%', margin: 'auto' }}>
                <div className="me-3">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 242 298">
                        <path d="M0 0 C1.1348584 0.00902344 2.2697168 0.01804687 3.43896484 0.02734375 C23.47151655 0.43680968 41.41912411 6.70304574 56.3125 20.4375 C57.21484375 21.24832031 58.1171875 22.05914062 59.046875 22.89453125 C74.64184376 37.60635951 83.83566074 56.654507 84.515625 78.19921875 C84.91226531 103.33914058 77.31737924 124.72514184 59.890625 142.98046875 C40.97964893 161.003202 19.30449904 166.00597218 -5.98828125 165.70703125 C-24.51895596 165.18551081 -41.2091323 156.90552754 -54.6875 144.4375 C-55.46480469 143.75171875 -56.24210937 143.0659375 -57.04296875 142.359375 C-69.96911262 130.34540307 -77.02755098 114.47604647 -80.6875 97.4375 C-80.87183594 96.68984375 -81.05617187 95.9421875 -81.24609375 95.171875 C-84.64002323 74.1475331 -79.10091145 52.42309131 -66.875 35.125 C-50.96205962 13.51721423 -27.09989319 -0.2604585 0 0 Z" fill={svgColorsFill[index % svgColorsFill.length]} transform="translate(120.6875,65.5625)" />
                        <path d="M0 0 C4.05372524 0.57364036 6.37581832 2.71370197 9.5 5.25 C20.3330382 13.59369492 30.48474783 16.62609543 44 15 C53.97696446 12.58871591 62.75068609 7.24931391 70 0 C72.125 0.375 72.125 0.375 74 1 C73.13997585 9.02296505 68.52361752 14.74005454 62.703125 20.125 C52.8943566 27.56171329 41.17778217 30.46133386 29 29 C16.24480776 26.22713212 7.35161713 18.47496825 0.265625 7.83203125 C-1.16218024 4.63709669 -1.07650968 3.2713933 0 0 Z" fill="#F5FCFD" transform="translate(87,161)" />
                        <path d="M0 0 C3.57123148 2.32906401 5.48872474 4.3317428 7.875 7.875 C7.6875 10.25 7.6875 10.25 6.875 11.875 C4.32390427 11.40257486 2.82653629 10.84038306 0.6875 9.3125 C-3.25065652 7.29966445 -6.7803585 7.11528127 -11.125 7.875 C-13.8950997 9.84009909 -13.8950997 9.84009909 -16.125 11.875 C-18.4375 11.625 -18.4375 11.625 -20.125 10.875 C-19.70943583 6.02675132 -17.75051755 3.99463138 -14.125 0.875 C-9.71018663 -2.21536936 -4.91150981 -1.76814353 0 0 Z" fill="#EDF9FC" transform="translate(164.125,129.125)" />

                        <path d="M0 0 C4.36537421 1.91957881 7.34871993 4.0230799 10 8 C9.625 10.1875 9.625 10.1875 9 12 C5.9229232 11.54748871 3.86133032 10.92162148 1.125 9.375 C-2.79422195 7.65054234 -4.89286103 7.93518619 -9 9 C-10.34275169 9.98731742 -11.67732273 10.98594743 -13 12 C-15.75 12.1875 -15.75 12.1875 -18 12 C-17.86210108 9.03517326 -17.71055664 6.78765516 -15.66796875 4.5234375 C-10.44699941 0.17898126 -6.68490273 -0.96713219 0 0 Z" fill="#EFFAFC" transform="translate(93,129)" />
                    </svg>
                </div>
                <div className="flex-grow-1">
                    <div className="fw-bold" style={{ fontSize: '14px', lineHeight: '17.5px' }}>{invitacion.usuarioEmisor}</div>
                    <div style={{ fontSize: '14px', color: '#888', lineHeight: '17.5px' }}>DNI - {invitacion.dniUsuarioEmisor}</div>
                </div>
            </div>
            <div
                className="d-flex gap-2 flex-column flex-sm-row mb-3"
                style={{ maxWidth: '100%' }}
            >
                <button
                    className="btn fw-bold"
                    style={{ color: '#EC008B', width: '100px', height: '41px' }}
                    onClick={onAceptar}
                >
                    Aceptar
                </button>
                <button
                    className="btn fw-normal"
                    style={{ color: '#0C1561', width: '100px', height: '41px' }}
                    onClick={onEliminar}
                >
                    Eliminar
                </button>
            </div>

        </div>
    );
};

export default CardSolicitudesFamilion;