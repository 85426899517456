export const brandName=null;

export const WebSocialShareAttributes={
  socialSharePopupWidth: null,
  socialSharePopupHeight: null,
  socialShareUrl:null,
  brandName:null
}

export const WebSocialShareWithTextAttributes ={
  socialShareText: null,
  socialSharePopupWidth: null,
  socialSharePopupHeight: null,
  socialShareUrl:null,
  brandName:null
}

export const WebSocialShareFacebookAttributes={
  socialShareType:null,
  socialShareVia:null,
  socialShareRedirectUri:null,
  socialShareTo:null,
  socialShareDisplay:null,
  socialShareRef:null,
  socialShareFrom:null,
  socialShareSource:null,
  socialShareQuote:null,
  socialShareMobileiframe:null,
  socialShareHashtags:null,
  socialSharePopupWidth: null,
  socialSharePopupHeight: null,
  socialShareUrl:null,
  brandName:null
}

export const WebSocialShareTwiterAttributes={
  socialShareVia: null,
  socialShareHashtags: null,
  socialShareText: null,
  socialSharePopupWidth: null,
  socialSharePopupHeight: null,
  socialShareUrl:null,
  brandName:null
}

export const WebSocialShareLinkedinAttributes={
  socialShareDescription:null,
  socialShareSource:null,
  socialShareText: null,
  socialSharePopupWidth: null,
  socialSharePopupHeight: null,
  socialShareUrl:null,
  brandName:null
}

export const WebSocialSharePinterestAttributes ={
  socialShareMedia: null,
  socialShareText: null,
  socialSharePopupWidth: null,
  socialSharePopupHeight: null,
  socialShareUrl:null,
  brandName:null
}

export const WebSocialShareRedditAttributes ={
  socialShareSubreddit:null,
  socialShareText: null,
  socialSharePopupWidth: null,
  socialSharePopupHeight: null,
  socialShareUrl:null,
  brandName:null
}

export const WebSocialShareEmailAttributes ={
  socialShareTo: null,
  socialShareBody: null,
  socialShareSubject: null,
  socialShareCc: null,
  socialShareBcc: null,
  brandName:null
}

export const WebSocialShareWhatsappAttributes ={
  socialShareText: null,
  socialSharePopupWidth: null,
  socialSharePopupHeight: null,
  socialShareUrl:null,
  brandName:null
}

export const WebSocialShareHackerNewsAttributes ={
  socialShareText: null,
  socialSharePopupWidth: null,
  socialSharePopupHeight: null,
  socialShareUrl:null,
  brandName:null
}
