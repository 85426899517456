
import React from "react";
import { useNavigate } from "react-router-dom";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import Onboarding from "../layout/Onboarding";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import ContentInvitacionesRecibidas from "./InvitacionesRecibidas";
import ContentInvitacionesEnviadas from "./InvitacionesEnviadas";

const HistorialDeInvitaciones = () => {

    let navigate = useNavigate();
    const svgColorsFill = ['#EB008B', '#A9006B', '#9D8D65', '#2169FB', '#00AFD7'];

    return (
        <>
            <AuthenticatedTemplate>
                <div
                    className="d-flex flex-column min-vh-100 w-100"
                    style={{ overflowX: 'hidden' }}
                >
                    <Header />
                    <div className="d-flex flex-row justify-content-between align-items-center p-3 text-gris" >
                        <button onClick={() => navigate(-1)} className="btn-none d-flex flex-row align-items-center text-decoration-none">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289C12.0976 3.68342 12.0976 4.31658 11.7071 4.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L11.7071 19.2929C12.0976 19.6834 12.0976 20.3166 11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L10.2929 3.29289Z" fill="#0C1561" />
                            </svg>
                            <p style={{
                                fontFamily: 'Encode Sans',
                                fontSize: '16px',
                                fontWeight: 500,
                                margin: '10px',
                                lineHeight: '20px',
                                textAlign: 'left',
                                color: '#0C1561',
                                height: '20px',
                                gap: '0px',
                            }}>
                                Invitaciones
                            </p>
                        </button>
                    </div>
                    <div className="w-100">
                        <ContentInvitacionesRecibidas svgColorsFill={svgColorsFill}/>
                        <ContentInvitacionesEnviadas svgColorsFill={svgColorsFill}/>
                    </div>
                    <Footer />
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Onboarding />
            </UnauthenticatedTemplate>
        </>
    );
};

export default HistorialDeInvitaciones;
