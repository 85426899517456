import React from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import {Link} from 'react-router-dom'



const PopupFaltanDatos=({modal, setModal})=>{

    const hideAlert = (popUp) => {
        if (popUp == "perfil") setModal({ ...modal, pefilIncompleto: false });
    }
    return <SweetAlert
                                title={<></>}
                                onCancel={() => hideAlert("perfil")}
                                onConfirm={() => hideAlert("perfil")}
                                showConfirm={false}
                                closeAnim={true}
                                closeBtnStyle={{ fontWeight: "400", right: "10px", top: "0px", color: "#656565" }}
                            >
                                <div className="px-3 py-3">
                                    <div className='mb-4'>
                                        <img className="position-absolute" style={{ top: '25px', right: '25px' }} src={`${window.location.origin}/img/salir-x.svg`} width="22px" height="22px" onClick={() => hideAlert("perfil")} />
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <p className='text-fucsia fs-24-600 my-2'>Completá tus datos para poder participar</p>
                                        <img className='my-3' src={`${window.location.origin}/img/concursos/faltanDatosConcursos.svg`} width="177px" height="156px" />
                                        <p className="fs-14-400 text-gris my-2">Para participar del concurso, es necesario que completes los datos en tu perfil. Nos importa conocerte para personalizar tu cuenta y darte mejores beneficios</p>
                                        <Link to={`/mi-perfil`} className='btn btn-azul fs-16-600 w-100 my-2'>Completar perfil</Link>
                                    </div>
                                </div>
                            </SweetAlert>
    
}

export default PopupFaltanDatos;