import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CustomModal from '../../utils/CustomModal';
import CardSolicitudesFamilion from './CardSolicitudesFamilion';
import familionContext from '../../context/familion/familionContext';

const ContentInvitacionesRecibidas = ({ svgColorsFill }) => {

    const { invitacionesRecibidas, getInvitacionesRecibidas, actualizarInvitacion } = useContext(familionContext);
    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            await getInvitacionesRecibidas();
        };
        fetchData();
    }, []);

    const showSuccessModal = (id) => {
        Swal.fire({
            title: '<span style="color: #EC008B; font-weight: 700;">Cuidado con tus cargas</span>',
            html: `
              <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                <img src="${window.location.origin}/img/familion/warningGrupoFamiliar.png" alt="Familion Icon" style="width: 200px; margin-bottom: 20px;" />
                <p style="
                  font-family: 'Encode Sans';
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 17.5px;
                  text-align: center;
                  color: #8F8E8E;
                ">
                Las cargas que ya sumaste no se trasladan. Si estás cerca de completar las 5, te recomendamos esperar a obtener tu cupón y unirte después.
                </p>
              </div>
            `,
            showCloseButton: true,
            confirmButtonText: 'Aceptar solicitud',
            confirmButtonColor: '#0033A0',
            customClass: {
                popup: 'swal2-popup-custom',
            },
            width: '520px',
            height: '410px',
            padding: '20px',
            background: '#FFF',
            backdrop: `
              rgba(0, 0, 0, 0.4)
              left top
              no-repeat
            `,

        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await actualizarInvitacion(id, true, 0);
                    if (response && response.statusCode === 200) {
                        navigate('/familion');
                    }
                } catch (err) {
                    if (err.response && err.response.data.detail) {
                        if (err.response.data.detail === 'Ya pertenece a un grupo familiar.') {
                            CustomModal({
                                title: '¡Ya sos miembro de un FamiliON!',
                                imageUrl: `${window.location.origin}/img/familion/FamilionLleno.png`,
                                message: 'Para poder aceptar esta solicitud, debés abandonar el grupo actual desde la sección <span style="color: #EC008C;">Integrantes</span>.',
                                showConfirmButton: false
                            });
                        } else if (err.response.data.detail === 'El grupo ya tiene el máximo de miembros permitidos.') {
                            CustomModal({
                                title: '¡FamiliON lleno!',
                                imageUrl: `${window.location.origin}/img/familion/FamilionLleno.png`,
                                message: 'El grupo ya alcanzó el número máximo de integrantes. No se pueden sumar más miembros.',
                                showConfirmButton: false
                            });
                        }
                    }
                }
            }
        });
    };

    const handleAceptar = (id) => {
        showSuccessModal(id)
    };

    const handleEliminar = async (id) => {
        await actualizarInvitacion(id, false, 1);
        await getInvitacionesRecibidas();
    };

    const invitacionesFiltradas = invitacionesRecibidas?.filter(invitacion => invitacion.aceptada === false && invitacion.stateCode != 1);

    return (
        <div className="row justify-content-center">
            <div className="w-100 col-md-10">
                <p style={{
                    fontFamily: 'Encode Sans',
                    fontSize: '16px',
                    fontWeight: 500,
                    margin: '10px',
                    marginBottom: '20px',
                    marginLeft: '20px',
                    lineHeight: '20px',
                    textAlign: 'left',
                    color: '#0C1561',
                    height: '20px',
                    gap: '0px',
                }}>
                    Solicitudes
                </p>
                <hr style={{ width: '96%', marginLeft: '2%' }} />
                <div className="d-flex flex-column gap-1" style={{ padding: '30px' }}>
                    {invitacionesFiltradas && invitacionesFiltradas.length > 0 ? (
                        invitacionesFiltradas.map((invitacion, index) => (
                            <CardSolicitudesFamilion
                                key={index}
                                invitacion={invitacion}
                                svgColorsFill={svgColorsFill}
                                index={index}
                                onAceptar={() => handleAceptar(invitacion.invitacionId)}
                                onEliminar={() => handleEliminar(invitacion.invitacionId)}
                            />
                        ))
                    ) : (
                        <div className="container d-flex flex-column justify-content-center align-items-center" style={{
                            maxWidth: '800px',
                            height: '200px',
                            margin: '0 auto',
                            textAlign: 'center',
                            border: 'none',
                            boxShadow: 'none',
                            padding: '20px'
                        }}>
                            <p style={{
                                color: '#8F8E8E',
                                fontFamily: 'Encode Sans',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '17.5px',
                            }}>
                                No tienes invitaciones pendientes.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContentInvitacionesRecibidas;