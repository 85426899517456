import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Steps({ step }) {
    
    const totalSteps = 4;
    const percentage = 100 / totalSteps;

    return (
        <Fragment>
            <div className="progress m-2">
                {Array.from({ length: totalSteps }, (_, index) => (
                    <div
                    key={index}
                    className={`progress-bar mx-1 ${step >= index + 1 ? "active" : ""}`}
                    role="progressbar"
                    style={{ width: `${percentage}%` }}
                    aria-valuenow={percentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    />
                ))}
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center p-3 text-gris">
                <Link to={step === 1 ? "/" : `/crear-cuenta/${step - 1}`} className="d-flex flex-row align-items-center text-decoration-none">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289C12.0976 3.68342 12.0976 4.31658 11.7071 4.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L11.7071 19.2929C12.0976 19.6834 12.0976 20.3166 11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L10.2929 3.29289Z" fill="#0C1561" />
                    </svg>
                    <span className="ms-2 fs-16-400 text-azul">Nueva cuenta</span>
                </Link>
                <p className="fs-16-400">{step}/{totalSteps}</p>
            </div>
        </Fragment>
    );
}

export default Steps;
