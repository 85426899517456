import React, { useReducer } from "react";
import clienteAxios from "../../config/axios";
import { FETCH_CONFIGURACION_REFERIDOS } from "../../types";
import configuracionGeneralContext from "./configuracionGeneralContext";
import configuracionGeneralReducer from "./configuracionGeneralReducer";

const ConfiguracionGeneralState = props => {
    const initialState = {
        referidos: null,
    }

    const [state, dispatch] = useReducer(configuracionGeneralReducer, initialState);

    const obtenerConfiguracionReferidos = async () => {
        try {
            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_REFERIDOS}/getconfiguracionreferidosactivo`);

            if (respuesta.status !== 204) {
                dispatch({
                    type: FETCH_CONFIGURACION_REFERIDOS,
                    payload: respuesta.data.response,
                })
            }

        } catch (err) {
            //
        }
    }

    return (
        <configuracionGeneralContext.Provider
            value={{
                referidos: state.referidos,
                cargando: state.cargando,
                obtenerConfiguracionReferidos,
            }}>
            {props.children}
        </configuracionGeneralContext.Provider>
    )
}

export default ConfiguracionGeneralState;


