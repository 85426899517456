import clienteAxios from '../config/axios';

const useActualizarInvitacionPendiente = () => {

    const actualizarInvitacionPendiente = async () => {
        
        try {
            const respuesta = await clienteAxios.post(`${process.env.REACT_APP_API_CONTEXT_APID365}/actualizarInvitacionPendiente`);
            
            if (respuesta.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    };
    return { actualizarInvitacionPendiente };
};

export default useActualizarInvitacionPendiente;