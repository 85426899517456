import React, { useReducer } from "react";
import {
    FETCH_FAMILION_HOME,
    FETCH_INVITACIONES_RECIBIDAS,
    FETCH_INVITACIONES_ENVIADAS,
    ACTUALIZAR_INVITACION,
    FETCH_INTEGRANTES_FAMILION,
    DELETE_INTEGRANTE_FAMILION,
    FETCH_TyC,
    MENSAJE_ERROR,
    FETCH_FAMILION_USERS,
    RESETEAR_ELIMINADOFAMILION
} from "../../types";
import familionReducer from "./familionReducer";
import familionContext from "./familionContext";
import clienteAxios from "../../config/axios";



const FamilionState = props => {
    const initialState = {
        familiON: null,
        invitacionesRecibidas: [],
        invitacionesEnviadas: [],
        integrantes: [],
        integranteEliminadoStatus:0
    }
    const [state, dispatch] = useReducer(familionReducer, initialState);
    
    const getFamiliONIntegrantes = async () => {
        try {
            const response = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getintegrantesfamilion`);
            dispatch({
                type: FETCH_FAMILION_USERS,
                payload: response.data.response,
            })

        } catch (err) {
            enviarError(err);
        }
    }

    const getFamiliONHome = async () => {
        try {

            const response = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/familion`);

            dispatch({
                type: FETCH_FAMILION_HOME,
                payload: response.data.response,
            })

        } catch (err) {
            enviarError(err);
        }
    }

    const getInvitacionesRecibidas = async () => {
        try {
            const response = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/GetListaInvitacionesRecibidas`);
            dispatch({
                type: FETCH_INVITACIONES_RECIBIDAS,
                payload: response.data.response,
            });
        } catch (err) {
            enviarError(err);
        }
    };

    const getIntegrantesFamilion = async () => {
        try {
            const response = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getintegrantesfamilion`);
            dispatch({
                type: FETCH_INTEGRANTES_FAMILION,
                payload: response.data.response,
            });
        } catch (err) {
            enviarError(err);
        }
    };

    const getInvitacionesEnviadas = async () => {
        try {
            const response = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/GetListaInvitacionesEnviadas`);
            dispatch({
                type: FETCH_INVITACIONES_ENVIADAS,
                payload: response.data.response,
            });
        } catch (err) {
            enviarError(err);
        }
    };

    const getTyCFamilion = async () => {
        try {
            const response = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/gettycfamilion`);
            dispatch({
                type: FETCH_TyC,
                payload: response.data.response,
            });
        } catch (err) {
            enviarError(err);
        }
    };

    const actualizarInvitacion = async (invitacionId, aceptada, stateCode) => {
        try {
            const response = await clienteAxios.post(`${process.env.REACT_APP_API_CONTEXT_APID365}/actualizarInvitacion`, {
                invitacionId: invitacionId,
                aceptada: aceptada,
                stateCode: stateCode
            });

            dispatch({
                type: ACTUALIZAR_INVITACION,
                payload: response.data,
            });

            return response.data;

        } catch (err) {
            enviarError(err);
            throw err;
        }
    };


    const deleteIntegrante = async (IDintegrante, IDgrupo) => {
        try {
            const response = await clienteAxios.post(`${process.env.REACT_APP_API_CONTEXT_APID365}/eliminarintegrantefamilion`, {
                integranteId: IDintegrante,
                familionId: IDgrupo
            });

            dispatch({
                type: DELETE_INTEGRANTE_FAMILION,
                payload: response.status,
            });

            return response.status;

        } catch (err) {
            enviarError(err);
        }
    };

    

    const enviarError = (err) => {
        var error = null;
        if (err.response !== undefined) error = err.response;
        if (err.data !== undefined) error = err;
        const alerta = {
            msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
            categoria: error !== undefined ? error.data.type : "error",
        }
        dispatch({
            type: MENSAJE_ERROR,
            payload: alerta,
        })
    }

    const resetearEliminadoFamilion = () => {
        dispatch({
            type: RESETEAR_ELIMINADOFAMILION,
        })
    }

    return (
        <familionContext.Provider
            value={{
                familiON: state.familiON,
                getFamiliONHome,
                getInvitacionesRecibidas,
                invitacionesRecibidas: state.invitacionesRecibidas,
                getInvitacionesEnviadas,
                invitacionesEnviadas: state.invitacionesEnviadas,
                actualizarInvitacion,
                getIntegrantesFamilion,
                integrantesFamilion: state.integrantes,
                deleteIntegrante,
                integranteEliminadoStatus: state.integrateEliminadoStatus,
                getTyCFamilion,
                TyC: state.tyc,
                integrantes: state.integrantes,
                getFamiliONIntegrantes,
                resetearEliminadoFamilion
            }}>
            {props.children}
        </familionContext.Provider>
    )
}
export default FamilionState;