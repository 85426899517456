import Swal from 'sweetalert2';

const CustomModal = ({ title, imageUrl, message, showConfirmButton, confirmButtonText }) => {
    Swal.fire({
        title: `<span style="color: #EC008B; font-weight: 700;">${title}</span>`,
        html: `
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                <img src="${imageUrl}" alt="Icon" style="width: 200px; margin-bottom: 20px;" />
                <p style="
                  font-family: 'Encode Sans';
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 17.5px;
                  text-align: center;
                  color: #8F8E8E;
                ">
                    ${message}
                </p>
            </div>
        `,
        showCloseButton: true,
        showConfirmButton: showConfirmButton,
        confirmButtonText: confirmButtonText,
        confirmButtonColor: '#0033A0',
        customClass: {
            popup: 'swal2-popup-custom',
        },
        width: '520px',
        padding: '20px',
        background: '#FFF',
        backdrop: `
          rgba(0, 0, 0, 0.4)
          left top
          no-repeat
        `,
    });
};

export default CustomModal;