import React, { useContext, useEffect, useState } from 'react'
import usuarioContext from '../../context/usuario/usuarioContext';
import { Link, useNavigate } from 'react-router-dom';
import Cargando from '../layout/Cargando';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Swal from 'sweetalert2';
import BackgroundHeader from '../layout/BackgroundHeader';
import { b2cPolicies } from '../../config/authConfig'
import Steps from '../layout/Steps';

const CrearCuentaCuatro = () => {

    const usuariosContext = useContext(usuarioContext);
    const { usuario, crearCuenta, mensaje, redirect, guardando, resetearMensaje, resetVerified } = usuariosContext;
    const { instance } = useMsal();

    const [formData, setFormData] = useState({
        apellido: '',
        nombre: '',
        dni: '',
        fechaCumpleanios: '',
        email: '',
        password: '',
        repetirPassword: '',
        codigoArea: '',
        celular: '',
        codigoReferente: ''
    });

    const [tyc, setTyc] = useState({
        termino1: false,
        termino2: false,
    })

    const [errores, setErrores] = useState({
        codigoArea: '',
        celular: '',
        tyc: ''
    })

    const [cargando, setCargando] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {

        resetVerified();

        if (mensaje && !Swal.isVisible()) {
            Swal.fire({
                html: `
                    <div class="d-flex flex-column gap-3 mt-3">
                        <p class="fs-24-bold text-rosa">${mensaje.categoria === 'warning' ? `¡Ya sos parte de ON!` : `Volvé a intentarlo`}</p>
                        <img src="${window.location.origin}/img/popups/${mensaje.categoria === 'warning' ? "crear-cuenta-existente" : "crear-cuenta-error"}.svg" />
                        <p class="fs-14-400 text-gris" id="msg">
                            ${detectPhoneNumbers(mensaje.msg)}
                        </p>
                        <button class="btn btn-azul fs-16-bold" id="handleResponse">
                            ${mensaje.categoria === 'warning' ? 'Iniciar sesión' : 'Lo intentaré luego'}
                        </button>
                    </div>`,
                showConfirmButton: false,
                showCloseButton: true,
                closeButtonHtml: `
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.2095 1.70711C18.6001 1.31658 18.6001 0.683417 18.2095 0.292893C17.819 -0.0976311 17.1859 -0.0976311 16.7953 0.292893L9.50122 7.587L2.20711 0.292893C1.81658 -0.0976311 1.18342 -0.0976311 0.792893 0.292893C0.402369 0.683417 0.402369 1.31658 0.792893 1.70711L8.087 9.00122L0.792893 16.2953C0.402369 16.6859 0.402369 17.319 0.792893 17.7095C1.18342 18.1001 1.81658 18.1001 2.20711 17.7095L9.50122 10.4154L16.7953 17.7095C17.1859 18.1001 17.819 18.1001 18.2095 17.7095C18.6001 17.319 18.6001 16.6859 18.2095 16.2953L10.9154 9.00122L18.2095 1.70711Z" fill="#8F8E8E" />
                    </svg>
                    `
            }).then(() => resetearMensaje())

            if (document.getElementById("handleResponse")) {
                document.getElementById("handleResponse").addEventListener("click", (e) => {
                    e.preventDefault();
                    setCargando(true);
                    Swal.close();
                    resetearMensaje();

                    window.dataLayer.push({ "event": "click_iniciar_sesion_on" })

                    if (mensaje.categoria === 'warning') {
                        instance.loginRedirect(b2cPolicies.authorities.loginRedirect);
                    } else {
                        window.location.reload();
                    }
                });
            }
        }

        if (usuario === null) {
            navigate("/crear-cuenta/1")
        } else {
            setFormData(usuario);
        }

        if (redirect) {
            navigate('/bienvenida')
        }

    }, [mensaje, redirect])

    function detectPhoneNumbers(text) {
        // Create a regular expression to match phone numbers in the format ####-###-####.
        const phoneExp = /\d{4}?[\s-]?\d{3}[\s-]?\d{4}/g;

        // Find all phone numbers in the text.
        const phonenumbers = text.match(phoneExp);

        // Loop through the phone numbers and apply a HTML class to each one.
        if (phonenumbers !== null)
            for (const phonenumber of phonenumbers) {
                text = text.replace(phonenumber, `<a href="tel:${phonenumber}" class="text-rosa text-decoration-none">${phonenumber}</a>`);
            }

        return text;
    }

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        validarCamposVacios(e.target.value);

        switch (e.target.name) {
            case "codigoArea":
            case "celular":
                setErrores({
                    ...errores,
                    [e.target.name]: /\d+$/.test(e.target.value) ? '' : "*Ingresá solo números"
                })
                break;
            default:
                break;
        }

        function validarCamposVacios(value) {
            setErrores({
                ...errores,
                [e.target.name]: value.length < 1 ? "*Completá este campo" : ""
            })
        }
    }

    const onChangeTyc = (e, type) => {
        setTyc({ ...tyc, [type]: e.target.checked })
    }

    const handleSubmit = e => {
        e.preventDefault();

        if (formValid(errores)) {

            crearCuenta(formData)

        }
    }

    const formValid = () => {

        let valid = true;
        let copy = { ...errores };
        const codigoAreaLength = formData.codigoArea.length;
        const celularLength = formData.celular.length;

        for (const error in errores) {
            if (error !== 'tyc') {
                errores[error].length > 0 && (valid = false);
            }
        }

        for (const field in formData) {
            if (
                field === 'codigoArea' ||
                field === 'celular'
            ) {
                formData[field].length < 1 && (valid = false);
                copy[field] = formData[field].length < 1 ? "*Completá este campo" : "";
            }
        }

        switch (true) {
            case codigoAreaLength < 2:
                copy["codigoArea"] = "*Ingresá un Código de área válido";
                valid = false;
                break;
            case codigoAreaLength == 2:
                if (!formData.codigoArea.match(11)) {
                    copy["codigoArea"] = "*Ingresá un Código de área válido";
                    valid = false;
                } else if (codigoAreaLength + celularLength !== 10) {
                    copy["celular"] = "*Ingresá un número de celular válido";
                    valid = false;
                }
                break;
            default:
                if (!formData.codigoArea.startsWith(2) && !formData.codigoArea.startsWith(3)) {
                    copy["codigoArea"] = "*Ingresá un Código de área válido";
                    valid = false;
                } else if (codigoAreaLength + celularLength !== 10) {
                    copy["celular"] = "*Ingresá un número de celular válido";
                    valid = false;
                }
                break;
        }

        if (!tyc.termino1 || !tyc.termino2) {
            copy["tyc"] = "Es necesario que acepte los términos y condiciones";
            valid = false;
        }

        setErrores(copy)

        return valid;
    };

    if (guardando || cargando) {
        return <Cargando />
    }

    return (
        <UnauthenticatedTemplate>
            <BackgroundHeader>
                <div className="d-flex flex-column vh-100 bg-header">
                    <Steps step={4} />
                    <div className="d-flex flex-column">
                        <div className="text-center my-4 px-5">
                            <p className="fs-18-bold">Estás a un paso</p>
                            <p className="fs-14-400">Dejanos tu celular y enterate de todas las novedades que ON tiene para vos</p>
                        </div>
                        <form noValidate onSubmit={handleSubmit} className="p-3">
                            <div className="my-4 row">
                                <div className="col-4">
                                    <label htmlFor="codigoArea" className="form-label fs-14">Cód Area</label>
                                    <input
                                        type="text"
                                        name="codigoArea"
                                        className={`form-control ${errores.codigoArea ? 'is-invalid' : ''}`}
                                        id="codigoArea"
                                        value={formData.codigoArea}
                                        required
                                        maxLength="4"
                                        onChange={onChange} />
                                    <div className="text-rosa fs-12-400 mt-1">
                                        Código de área sin 0
                                    </div>
                                    <div className="invalid-feedback campo-invalido">
                                        {errores.codigoArea}
                                    </div>
                                </div>
                                <div className="col-8">
                                    <label htmlFor="celular" className="form-label fs-14">Celular</label>
                                    <input
                                        type="text"
                                        name="celular"
                                        className={`form-control ${errores.celular ? 'is-invalid' : ''}`}
                                        id="celular"
                                        value={formData.celular}
                                        placeholder="Ingresá tu celular"
                                        maxLength="8"
                                        required
                                        onChange={onChange} />
                                    <div className="text-rosa fs-12-400 mt-1">
                                        Número sin el 15
                                    </div>
                                    <div className="invalid-feedback campo-invalido">
                                        {errores.celular}
                                    </div>
                                </div>
                            </div>
                            <div className="campo-invalido my-3">
                                {errores.tyc}
                            </div>
                            <div className="d-flex flex-row align-items-start mb-2 align-content-center">
                                <label
                                    className={tyc.termino1 ? 'active' : ''}
                                    htmlFor="tyc1">
                                    <input
                                        type="checkbox"
                                        className="btn-check"
                                        value={tyc.termino1}
                                        id="tyc1"
                                        onChange={(e) => onChangeTyc(e, "termino1")}
                                        checked={tyc.termino1}
                                    />
                                    <div>
                                        <svg className="checked" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" fillOpacity="0.6" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.5 10.6002L2.95 7.0502L2.25 7.7502L5.8 11.3002L6.5 12.0002L13.55 4.9502L12.85 4.2002L6.5 10.6002Z" fill="#0C1561" />
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#0C1561" />
                                        </svg>
                                        <svg className="unchecked" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" fillOpacity="0.6" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13 8.5C13 9.05229 12.5523 9.5 12 9.5L4 9.5C3.44771 9.5 3 9.05228 3 8.5C3 7.94772 3.44771 7.5 4 7.5L12 7.5C12.5523 7.5 13 7.94772 13 8.5Z" fill="#8F8E8E" />
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#C7C9D1" />
                                        </svg>
                                    </div>
                                </label>
                                <p className="text-muted fs-13 ms-2">
                                    <span>He leído y acepto los </span>
                                    <Link
                                        to={"/legales/2"}
                                        className="fw-bold text-rosa text-decoration-none text-reset tyc">Términos y Condiciones
                                    </Link><span> de uso del Sitio y la </span>
                                    <Link
                                        to={"/legales/4"}
                                        className="fw-bold text-rosa text-decoration-none text-reset tyc">Política de Privacidad
                                    </Link>.
                                </p>
                            </div>

                            <div className="d-flex flex-row align-items-start mb-4 align-content-center">
                                <label
                                    className={tyc.termino2 ? 'active' : ''}
                                    htmlFor="tyc2">
                                    <input
                                        type="checkbox"
                                        className="btn-check"
                                        value={tyc.termino2}
                                        id="tyc2"
                                        onChange={(e) => onChangeTyc(e, "termino2")}
                                        checked={tyc.termino2}
                                    />
                                    <div>
                                        <svg className="checked" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" fillOpacity="0.6" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.5 10.6002L2.95 7.0502L2.25 7.7502L5.8 11.3002L6.5 12.0002L13.55 4.9502L12.85 4.2002L6.5 10.6002Z" fill="#0C1561" />
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#0C1561" />
                                        </svg>
                                        <svg className="unchecked" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" fillOpacity="0.6" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13 8.5C13 9.05229 12.5523 9.5 12 9.5L4 9.5C3.44771 9.5 3 9.05228 3 8.5C3 7.94772 3.44771 7.5 4 7.5L12 7.5C12.5523 7.5 13 7.94772 13 8.5Z" fill="#8F8E8E" />
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#C7C9D1" />
                                        </svg>
                                    </div>
                                </label>
                                <p className="text-muted fs-13 ms-2">
                                    He leído y acepto los <Link
                                        to={"/legales/1"}
                                        className="fw-bold text-rosa text-decoration-none text-reset tyc">Términos y Condiciones
                                    </Link> del Programa Fidelidad ON
                                </p>
                            </div>

                            <div className="mt-auto d-grid gap-2">
                                {formData?.celular && formData?.codigoArea && tyc.termino1 != false && tyc.termino2 != false
                                    ?
                                        <button
                                            type="submit"
                                            className="btn btn-azul fs-16-bold"
                                        >
                                            Registrarme
                                        </button>
                                    :
                                        <button type="submit" disabled className="btn btn-azul disable fs-16-bold">
                                            Registrarme
                                        </button>
                                }
                            </div>
                        </form>
                    </div>
                    <Link
                        to={"/ayuda"}
                        className="fs-12-400 d-flex justify-content-center align-items-center mt-auto mb-3 text-decoration-none"
                        onClick={() => window.dataLayer.push({
                            "event": "click_ayuda",
                            "category": "crear-cuenta-4"
                        })}
                    >
                        <p className="me-2 text-gris">Ayuda</p>
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2.5 11C2.5 6.02944 6.52944 2 11.5 2C16.4706 2 20.5 6.02944 20.5 11C20.5 15.9706 16.4706 20 11.5 20C6.52944 20 2.5 15.9706 2.5 11ZM11.5 0C5.42487 0 0.5 4.92487 0.5 11C0.5 17.0751 5.42487 22 11.5 22C17.5751 22 22.5 17.0751 22.5 11C22.5 4.92487 17.5751 0 11.5 0ZM11.7705 7.02957C11.2772 6.94741 10.7714 7.03799 10.344 7.28184C9.9173 7.52535 9.59992 7.90351 9.44047 8.34362C9.25234 8.86287 8.67889 9.1313 8.15964 8.94317C7.64038 8.75504 7.37195 8.1816 7.56008 7.66234C7.88455 6.76677 8.52232 6.01866 9.35281 5.54476C10.1827 5.07119 11.1551 4.89953 12.099 5.05674C13.0432 5.21399 13.9056 5.6915 14.5304 6.41213C15.1555 7.1332 15.5015 8.0507 15.5003 9.00279C15.5 10.5466 14.3242 11.5565 13.4999 12.09C13.0536 12.3789 12.6147 12.5913 12.2914 12.7308C12.1284 12.8012 11.9911 12.8545 11.8924 12.8911C11.8429 12.9093 11.8029 12.9235 11.7738 12.9335L11.7385 12.9455L11.7274 12.9492L11.7235 12.9505L11.7219 12.951C11.7217 12.9511 11.7207 12.9514 11.4189 12.0188L11.7207 12.9514C11.1953 13.1215 10.6314 12.8334 10.4614 12.3079C10.2914 11.7828 10.5791 11.2193 11.104 11.0489L11.1211 11.043C11.1373 11.0375 11.1636 11.0282 11.1985 11.0153C11.2686 10.9893 11.3726 10.949 11.499 10.8945C11.7547 10.7842 12.0876 10.6218 12.4131 10.4111C13.1325 9.94545 13.5003 9.45664 13.5003 9.00223L13.5003 9.0007C13.501 8.53724 13.333 8.08416 13.0192 7.72228C12.7049 7.3597 12.2636 7.11169 11.7705 7.02957ZM10.5003 16C10.5003 15.1716 11.1674 14.5 11.9903 14.5H12.0102C12.8331 14.5 13.5003 15.1716 13.5003 16C13.5003 16.8284 12.8331 17.5 12.0102 17.5H11.9903C11.1674 17.5 10.5003 16.8284 10.5003 16Z" fill="#8F8E8E" />
                        </svg>
                    </Link>
                </div>
            </BackgroundHeader>
        </UnauthenticatedTemplate >
    )
}

export default CrearCuentaCuatro;