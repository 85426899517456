import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const InvitacionFamilionModal = ({ show, onHide, onConfirm }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            dialogClassName="custom-modal"
            style={{
                borderRadius: '15px',
                boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
            }}
        >
            <div
                style={{
                    height: '460px',
                    minHeight: '460px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Modal.Header
                    style={{
                        borderBottom: 'none',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    <Modal.Title
                        style={{
                            color: '#EC008B',
                            fontWeight: '700',
                            fontSize: '22px',
                            textAlign: 'center',
                            marginTop: '15px',
                        }}
                    >
                        ¡Te Invitaron a un FamiliON!
                    </Modal.Title>
                    <button
                        type="button"
                        onClick={onHide}
                        style={{
                            width: '40px',
                            height: '40px',
                            position: 'absolute',
                            top: '10px',
                            right: '15px',
                            fontSize: '40px',
                            color: '#cccccc',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            transition: 'color 0.3s ease',
                        }}
                        onMouseEnter={(e) => (e.target.style.color = '#F27474')}
                        onMouseLeave={(e) => (e.target.style.color = '#cccccc')}
                    >
                        &times;
                    </button>
                </Modal.Header>
                <Modal.Body className="text-center" style={{ padding: '20px' }}>
                    <img
                        src={`${window.location.origin}/img/familion/FamilionInvite.png`}
                        alt="Familion Icon"
                        style={{
                            width: '200px',
                            height:'80',
                            marginBottom: '15px',
                        }}
                    />
                    <p
                        style={{
                            fontFamily: 'Encode Sans',
                            fontSize: '18px',
                            fontWeight: '400',
                            lineHeight: '17.5px',
                            textAlign: 'center',
                            color: '#8F8E8E',
                        }}
                    >
                        Te invitaron a formar parte de su grupo. ¡Aceptá para sumar puntos y cargas juntos!
                    </p>
                </Modal.Body>
                <Modal.Footer
                    style={{
                        justifyContent: 'center',
                        borderTop: 'none',
                        paddingBottom: '20px',
                    }}
                >
                    <Button
                        variant="primary"
                        onClick={onConfirm}
                        style={{
                            background: 'linear-gradient(90deg, #004A9F 0%, #002D75 100%)',
                            borderColor: 'transparent',
                            width: '100%',
                            borderRadius: '8px',
                            padding: '10px 25px',
                            fontSize: '16px',
                            fontWeight: '500',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        Saber más
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default InvitacionFamilionModal;