import { FETCH_CONFIGURACION_REFERIDOS } from "../../types";


const configuracionGeneralReducer = (state, action) => {
    switch (action.type) {
        case FETCH_CONFIGURACION_REFERIDOS:
            return {
                ...state,
                referidos: action.payload,
            }
        default:
            return state;

    }
}

export default configuracionGeneralReducer