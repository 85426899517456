import { Routes, Route } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import './App.css';
import UsuarioState from "./context/usuario/usuarioState";
import VehiculosState from "./context/vehiculos/vehiculosState";
import CategoriasFAQs from './components/ayuda/CategoriasFAQs';
import FaqsState from './context/faqs/faqsState';
import BeneficiosState from './context/beneficios/beneficiosState';
import { MsalProvider } from '@azure/msal-react';
import useGaTracker from './config/useGaTracker';
import './config/modifiedStyling.css';
import ComodinesState from './context/comodines/comodinesState';
import NovedadesState from './context/novedades/novedadesState';
import ConcursosState from './context/concursos/concursosState';
import LegalesState from './context/legales/legalesState';
import ComunidadesState from './context/comunidades/comunidadesState';
import PromocionesState from './context/promociones/promocionesState';

import Cargando from './components/layout/Cargando';
import CuponesOnboarding from './components/onboardings/CuponesOnboarding';
import BeneficiosBusqueda from './components/beneficios/BeneficiosBusqueda';
import ComodinesOnBording from './components/onboardings/comodinesOnBording';
import ProtectedRoute from './config/ProtectedRoute';
import ConcursoInicio from './components/concursos/ConcursoInicio';
import CompartirCupon from './components/compartir-cupon/CompartirCupon';
import ONStreamState from './context/onstream/onstreamState';
import PreferenciasState from './context/preferencias/preferenciasState';
import CrearCuentaCuatro from './components/usuario/CrearCuentaCuatro';
import FamilionState from './context/familion/familionState';
import HistorialDeInvitaciones from './components/familion/HistorialDeInvitaciones';
import ConfiguracionGeneralState from './context/configuracion-general/configuracionGeneralState';

const AddToHomeScreen = lazy(() => import('./config/AddToHomeScreen'));
const Home = lazy(() => import('./components/home/Home'));
const Perfil = lazy(() => import('./components/mi-perfil/Perfil'));
const Onboarding = lazy(() => import('./components/layout/Onboarding'));
const Bienvenida = lazy(() => import('./components/login/Bienvenida'));
const CrearCuentaUno = lazy(() => import('./components/usuario/CrearCuentaUno'));
const CrearCuentaDos = lazy(() => import('./components/usuario/CrearCuentaDos'));
const CrearCuentaTres = lazy(() => import('./components/usuario/CrearCuentaTres'));
const Marca = lazy(() => import('./components/vehiculos/Marca'));
const Modelo = lazy(() => import('./components/vehiculos/Modelo'));
const Tipo = lazy(() => import('./components/vehiculos/Tipo'));
const Beneficios = lazy(() => import('./components/beneficios/Beneficios'));
const FAQs = lazy(() => import('./components/ayuda/FAQs'));
const Contacto = lazy(() => import('./components/ayuda/Contacto'));
const Cargas = lazy(() => import('./components/mi-perfil/Cargas'));
const Sucursales = lazy(() => import('./components/sucursales/Sucursales'));
const Mapa = lazy(() => import('./components/geolocalizacion/Mapa'));
const Cupones = lazy(() => import('./components/beneficios/Cupones'));
const Niveles = lazy(() => import('./components/mi-perfil/Niveles'));
const Beneficio = lazy(() => import('./components/beneficios/Beneficio'));
const BeneficioPorCategoria = lazy(() => import('./components/beneficios/BeneficiosPorCategoria'));
const TerminosYCondiciones = lazy(() => import('./components/usuario/TerminosYCondiciones'));
const Estaciones = lazy(() => import('./components/geolocalizacion/Estaciones'));
const Monedas = lazy(() => import('./components/mi-perfil/Monedas'));
const Comodines = lazy(() => import('./components/mi-perfil/Comodines'));
const Novedades = lazy(() => import('./components/novedades/Novedades'));
const Novedad = lazy(() => import('./components/novedades/Novedad'));
const Concursos = lazy(() => import('./components/concursos/Concurso'));
const Legales = lazy(() => import('./components/legales/Legales'));
const Politica = lazy(() => import('./components/legales/Politica'));
const Comunidades = lazy(() => import('./components/comunidades/comunidades'));
const ONStream = lazy(() => import('./components/ON Stream/ONStream'));
const ONStreamPorCategoria = lazy(() => import('./components/ON Stream/ONStreamPorCategoria'));
const Promocion = lazy(() => import('./components/promociones/Promocion'));
const CodigoReferido = lazy(() => import('./components/amigos-referidos/codigo-referido'));
const FamiliONHome = lazy(() => import('./components/familion/Home'));
const FamiliONTyC = lazy(() => import('./components/familion/TyC'));
const FamilionOnboarding = lazy(() => import('./components/familion/FamilionOnboarding'));
const Integrantes = lazy(() => import('./components/familion/Integrantes'));
const InvitarFamilion = lazy(() => import('./components/familion/InvitarFamilion'));

function App({ msalInstance }) {

    useGaTracker();

    return (
        <MsalProvider instance={msalInstance}>
            <UsuarioState>
                <VehiculosState>
                    <BeneficiosState>
                        <NovedadesState>
                            <FaqsState>
                                <ComodinesState>
                                    <ConcursosState>
                                        <LegalesState>
                                            <ComunidadesState>
                                                <ONStreamState>
                                                    <PromocionesState>
                                                        <PreferenciasState>
                                                            <FamilionState>
                                                                <ConfiguracionGeneralState>
                                                                    <Suspense fallback={<Cargando />}>
                                                                        <AddToHomeScreen
                                                                            appId='Axion ON A2HS'
                                                                            startAutomatically={true}
                                                                            startDelay={1}
                                                                            lifespan={30}
                                                                            skipFirstVisit={false}
                                                                            displayPace={0}
                                                                            customPromptContent={{
                                                                                title: 'Guardar esta página en mi celular',
                                                                                cancelMsg: '',
                                                                                installMsg: 'Aceptar',
                                                                                guidanceCancelMsg: ''
                                                                            }}
                                                                            customPromptElements={{
                                                                                container: 'athContainer',
                                                                                containerAddOns: '',
                                                                                banner: 'athBanner',
                                                                                logoCell: 'athLogoCell',
                                                                                logoCellAddOns: 'athContentCell',
                                                                                logo: 'athLogo',
                                                                                titleCell: 'athTitleCell',
                                                                                titleCellAddOns: 'athContentCell',
                                                                                title: 'athTitle',
                                                                                cancelButtonCell: 'athCancelButtonCell',
                                                                                cancelButtonCellAddOns: 'athButtonCell',
                                                                                cancelButton: 'athCancelButton',
                                                                                installButtonCell: 'athInstallButtonCell',
                                                                                installButtonCellAddOns: 'athButtonCell',
                                                                                installButton: 'athInstallButton',
                                                                                installButtonAddOns: 'button',
                                                                                guidance: 'athGuidance',
                                                                                guidanceImageCell: 'athGuidanceImageCell',
                                                                                guidanceImageCellAddOns: '',
                                                                                guidanceCancelButton: 'athGuidanceCancelButton'
                                                                            }}
                                                                        />
                                                                        <Routes>
                                                                            <Route path="/" element={<Onboarding />} />
                                                                            <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                                                                            <Route path="/crear-cuenta/1" element={<CrearCuentaUno />} />
                                                                            <Route path="/crear-cuenta/2" element={<CrearCuentaDos />} />
                                                                            <Route path="/crear-cuenta/3" element={<CrearCuentaTres />} />
                                                                            <Route path="/crear-cuenta/4" element={<CrearCuentaCuatro />} />
                                                                            <Route path="/bienvenida" element={<Bienvenida />} />
                                                                            <Route path="/codigo-referido" element={<ProtectedRoute><CodigoReferido /></ProtectedRoute>} />
                                                                            <Route path="/crear-vehiculo/:path" element={<ProtectedRoute><Tipo /></ProtectedRoute>} />
                                                                            <Route path="/crear-vehiculo/:path/:tipo" element={<ProtectedRoute><Marca /></ProtectedRoute>} />
                                                                            <Route path="/crear-vehiculo/:path/:tipo/:marca" element={<ProtectedRoute><Modelo /></ProtectedRoute>} />
                                                                            <Route path="/terminos-y-condiciones" element={<TerminosYCondiciones />} />
                                                                            <Route path="/mi-perfil" element={<ProtectedRoute><Perfil /></ProtectedRoute>} />
                                                                            <Route path="/mis-cargas" element={<ProtectedRoute><Cargas /></ProtectedRoute>} />
                                                                            <Route path="/niveles" element={<ProtectedRoute><Niveles /></ProtectedRoute>} />
                                                                            <Route path="/mis-monedas" element={<ProtectedRoute><Monedas /></ProtectedRoute>} />
                                                                            <Route path="/cupones" element={<ProtectedRoute><Cupones /></ProtectedRoute>} />
                                                                            <Route path="/ayuda" element={<CategoriasFAQs />} />
                                                                            <Route path="/ayuda/:id" element={<FAQs />} />
                                                                            <Route path="/ayuda/contacto" element={<ProtectedRoute><Contacto /></ProtectedRoute>} />
                                                                            <Route path="/mapa" element={<ProtectedRoute><Mapa /></ProtectedRoute>} />
                                                                            <Route path="/beneficios" element={<ProtectedRoute><Beneficios /></ProtectedRoute>} />
                                                                            <Route path="/beneficios/:categoria" element={<ProtectedRoute><BeneficioPorCategoria /></ProtectedRoute>} />
                                                                            <Route path="/beneficios/:categoria/:id" element={<ProtectedRoute><Beneficio /></ProtectedRoute>} />
                                                                            <Route path="/beneficios/:categoria/:id/sucursales" element={<ProtectedRoute><Sucursales /></ProtectedRoute>} />
                                                                            <Route path="/beneficios/:categoria/:id/sucursales/mapa" element={<ProtectedRoute><Mapa /></ProtectedRoute>} />
                                                                            <Route path="/beneficios/busqueda" element={<ProtectedRoute><BeneficiosBusqueda /></ProtectedRoute>} />
                                                                            <Route path="/beneficios/mapa" element={<ProtectedRoute><Mapa /></ProtectedRoute>} />
                                                                            <Route path="/estaciones" element={<ProtectedRoute><Estaciones /></ProtectedRoute>} />
                                                                            <Route path="/comodines" element={<ProtectedRoute><Comodines /></ProtectedRoute>} />
                                                                            <Route path="/comodines/onboarding" element={<ProtectedRoute><ComodinesOnBording /></ProtectedRoute>} />
                                                                            <Route path="/novedades" element={<ProtectedRoute><Novedades /></ProtectedRoute>} />
                                                                            <Route path="/novedades/:id" element={<ProtectedRoute><Novedad /></ProtectedRoute>} />
                                                                            <Route path="/concursos-participar/:id" element={<ProtectedRoute><Concursos /></ProtectedRoute>} />
                                                                            <Route path="/concurso/:id" element={<ProtectedRoute><ConcursoInicio /></ProtectedRoute>} />
                                                                            <Route path="/legales" element={<Legales />} />
                                                                            <Route path="/legales/:id" element={<Politica />} />
                                                                            <Route path="/cuponesonboarding" element={<ProtectedRoute><CuponesOnboarding /></ProtectedRoute>} />
                                                                            <Route path="/compartir-cupon" element={<ProtectedRoute><CompartirCupon /></ProtectedRoute>} />
                                                                            <Route path="/comunidades" element={<ProtectedRoute><Comunidades /></ProtectedRoute>} />
                                                                            <Route path="/onstream" element={<ProtectedRoute><ONStream /></ProtectedRoute>} />
                                                                            <Route path="/onstream/:categoriaid" element={<ProtectedRoute><ONStreamPorCategoria /></ProtectedRoute>} />
                                                                            <Route path="/promocion/:id" element={<ProtectedRoute><Promocion /></ProtectedRoute>} />
                                                                            <Route path="/familion">
                                                                                <Route index={true} element={<ProtectedRoute><FamiliONHome /></ProtectedRoute>} />
                                                                                <Route path="terminos-y-condiciones" element={<ProtectedRoute><FamiliONTyC /></ProtectedRoute>} />
                                                                                <Route path="invitaciones" element={<ProtectedRoute> <HistorialDeInvitaciones /> </ProtectedRoute>} />
                                                                                <Route path="onboarding" element={<ProtectedRoute><FamilionOnboarding /></ProtectedRoute>} />
                                                                                <Route path="integrantes" element={<ProtectedRoute><Integrantes /></ProtectedRoute>} />
                                                                                <Route path="invitar" element={<ProtectedRoute><InvitarFamilion /></ProtectedRoute>} />
                                                                            </Route>
                                                                        </Routes>
                                                                    </Suspense>
                                                                </ConfiguracionGeneralState>
                                                            </FamilionState>
                                                        </PreferenciasState>
                                                    </PromocionesState>
                                                </ONStreamState>
                                            </ComunidadesState>
                                        </LegalesState>
                                    </ConcursosState>
                                </ComodinesState>
                            </FaqsState>
                        </NovedadesState>
                    </BeneficiosState>
                </VehiculosState>
            </UsuarioState>
        </MsalProvider>
    );
}

export default App;