import React, { useReducer } from "react";
import clienteAxios from "../../config/axios";
import { MENSAJE_ERROR, OBTENER_LEGALES, OBTENER_NOVEDAD, OBTENER_NOVEDADES, OBTENER_POLITICA, RESETEAR_CARGANDO, RESETEAR_MENSAJE } from "../../types";
import legalesReducer from "./legalesReducer";
import legalesContext from "./legalesContext";


const LegalesState = props => {
    const initialState = {
        mensajeLegales: null,
        legales: [],
        politica: null,
        novedad: null,
        cargando: true,
    }

    const [state, dispatch] = useReducer(legalesReducer, initialState);

    const obtenerLegales = async () => {
        try {

            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getlegales`)
            if(respuesta.data?.statusCode==206){
                enviarError(respuesta);  
            }
            else{
                // const respuesta = [
                //     {
                //         tipo: 1,
                //         titulo: "Términos y Condiciones",
                //         subtitulo: "Lea atentamente los terminos y condiciones de uso de la aplicacion",
                //         cuerpo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt enim ut eros ullamcorper, vitae feugiat justo porta. Proin tincidunt tincidunt placerat. Nulla facilisi. Nulla justo odio, iaculis nec lacus ut, sodales posuere eros. Nullam hendrerit non ipsum vel cursus. Nam semper nunc non metus vestibulum mollis. Sed erat libero, vulputate non iaculis ut, aliquam ut diam."
                //     },
                //     {
                //         tipo: 2,
                //         titulo: "Política de privacidad",
                //         subtitulo: "Lea atentamente los terminos y condiciones de uso de la aplicacion",
                //         cuerpo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt enim ut eros ullamcorper, vitae feugiat justo porta. Proin tincidunt tincidunt placerat. Nulla facilisi. Nulla justo odio, iaculis nec lacus ut, sodales posuere eros. Nullam hendrerit non ipsum vel cursus. Nam semper nunc non metus vestibulum mollis. Sed erat libero, vulputate non iaculis ut, aliquam ut diam."
                //     },
                //     {
                //         tipo: 3,
                //         titulo: "Términos y Condiciones Concurso",
                //         subtitulo: "Lea atentamente los terminos y condiciones de uso de la aplicacion",
                //         cuerpo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt enim ut eros ullamcorper, vitae feugiat justo porta. Proin tincidunt tincidunt placerat. Nulla facilisi. Nulla justo odio, iaculis nec lacus ut, sodales posuere eros. Nullam hendrerit non ipsum vel cursus. Nam semper nunc non metus vestibulum mollis. Sed erat libero, vulputate non iaculis ut, aliquam ut diam."
                //     }
                // ]

                dispatch({
                    type: OBTENER_LEGALES,
                    payload: respuesta.data.response,
                    // payload: respuesta
                })
            }

        } catch (err) {
            enviarError(err);
        }
    }

    const asignarPolitica = async (politica) => {
        dispatch({
            type: OBTENER_POLITICA,
            payload: politica
        })
    }

    const obtenerPolitica = async (id) => {
        try {

            resetearCargando();
            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getlegales/${id}`);
            if(respuesta.data?.statusCode==206){
                enviarError(respuesta);  
            }
            else{
                // const respuesta = {
                //     tipo: 1,
                //     titulo: "Términos y Condiciones Concurso",
                //     subtitulo: "Lea atentamente los terminos y condiciones de uso de la aplicacion",
                //     cuerpo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt enim ut eros ullamcorper, vitae feugiat justo porta. Proin tincidunt tincidunt placerat. Nulla facilisi. Nulla justo odio, iaculis nec lacus ut, sodales posuere eros. Nullam hendrerit non ipsum vel cursus. Nam semper nunc non metus vestibulum mollis. Sed erat libero, vulputate non iaculis ut, aliquam ut diam."
                // }

                dispatch({
                    type: OBTENER_POLITICA,
                    payload: respuesta.data.response,
                    // payload: respuesta
                })
            }

        } catch (err) {
            enviarError(err);
        }
    }

    const enviarError = (err) => {

        var error=null;
        if(err.response!==undefined) error = err.response;
        if(err.data!==undefined) error = err;

        const alerta = {
            msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
            categoria: error !== undefined ? error.data.type : "error",
        }
        dispatch({
            type: MENSAJE_ERROR,
            payload: alerta,
        })
    }

    const resetearMensajeLegales = () => {
        dispatch({
            type: RESETEAR_MENSAJE,
        })
    }

    const resetearCargando = () => {
        dispatch({
            type: RESETEAR_CARGANDO,
        })
    }

    return (
        <legalesContext.Provider
            value={{
                legales: state.legales,
                politica: state.politica,
                mensajeLegales: state.mensajeLegales,
                cargando: state.cargando,
                obtenerPolitica,
                obtenerLegales,
                asignarPolitica,
                resetearMensajeLegales,
                resetearCargando
            }}>
            {props.children}
        </legalesContext.Provider>
    )
}

export default LegalesState;


