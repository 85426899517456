import React, { useReducer } from "react";
import promocionesContext from "./promocionesContext";
import promocionesReducer from "./promocionesReducer";
import clienteAxios from "../../config/axios";

import {
    MENSAJE_ERROR,
    RESETEAR_MENSAJE,
    OBTENER_PROMOCIONES,
    OBTENER_PROMOCION,
    LIMPIAR_PROMOCION_ID
} from "../../types";


const PromocionesState = props => {
    const initialState = {
        mensajepromociones:null,
        cargandopromociones:null,
        promociones: null,
        promocion:null
    }
    const [state, dispatch] = useReducer(promocionesReducer, initialState);


    const obtenerPromociones = async () => {   

        try {
            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_PROMOCIONES}/getpromociones`);
            
             dispatch({
                 type: OBTENER_PROMOCIONES,
                 payload: respuesta.data.response,
             })
        } catch (e) {
            // enviarError(e);
        }
    }

    const obtenerPromocion = async () => {
        try {
            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_APID365}/getpromocion`);
            dispatch({
                type: OBTENER_PROMOCION,
                payload: respuesta.data.response,
            })

        } catch (e) {
            enviarError(e);
        }
    }

    const enviarError = (err) => {
        var error=null;
        if(err.response!==undefined) error = err.response;
        if(err.data!==undefined) error = err;

        const alerta = {
            msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
            categoria: error !== undefined ? error.data.type : "error",
        }
        dispatch({
            type: MENSAJE_ERROR,
            payload: alerta,
        })
    }

    const limpiarPromocionId = () => {
        dispatch({ type: LIMPIAR_PROMOCION_ID})
    }

    const resetearMensajePromociones = () => {
        dispatch({type: RESETEAR_MENSAJE})
    }

    return (
        <promocionesContext.Provider
            value={{
                cargandopromociones:state.cargandopromociones,
                mensajepromociones:state.mensajepromociones,
                promociones: state.promociones,
                promocion: state.promocion,
                obtenerPromociones,
                obtenerPromocion,
                resetearMensajePromociones,
                limpiarPromocionId,
            }}>
            {props.children}
        </promocionesContext.Provider>
    )
}
export default PromocionesState;