import React, { useReducer } from "react";
import clienteAxios from "../../config/axios";
import { CANJEAR_COMODIN, MENSAJE_ERROR, OBTENER_COMODINES, RESETEAR_CARGANDO, RESETEAR_MENSAJE, SET_NROS_SOCIO } from "../../types";
import comodinesReducer from "./comodinesReducer";
import comodinesContext from "./comodinesContext";

const ComodinesState = props => {
    const initialState = {
        comodines: null,
        mensajeComodines: null,
        cargandoComodines: true,
        nrosSocio: []
    }

    const [state, dispatch] = useReducer(comodinesReducer, initialState);


    const obtenerComodines = async () => {
        try {

            resetearCargando();

            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CUPONES}/getcomodines`);
            const aux = Array();

            if (respuesta.data?.length > 0) {
                for (const x in respuesta.data) {
                    aux.push('');
                }
            }

            dispatch({
                type: OBTENER_COMODINES,
                payload: {
                    comodines: respuesta.data,
                    nrosSocio: aux
                }
            })

        } catch (err) {
            enviarError(err);
        }
    }

    
    const canjearComodin = async (nroSocio, idComodin) => {
        try {

            let alerta = {
                idComodin: idComodin,
                msg: "",
                categoria: ""
            }

            if (nroSocio !== '') {
                const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CUPONES}/asignarcomodin/${nroSocio}/${idComodin}`);
                if(respuesta.data?.statusCode==206){
                    enviarError(respuesta);  
                }
                else{
                    alerta = {
                        idComodin: idComodin,
                        msg: "",
                        categoria: "success"
                    }

                    obtenerComodines();
                }


            } else {
                alerta = {
                    idComodin: idComodin,
                    msg: "*Completá este campo para activar comodín",
                    categoria: "error"
                }
            }

            dispatch({
                type: CANJEAR_COMODIN,
                payload: alerta
            })
          

        } catch (err) {
            enviarError(err);
        }
    }

    const setNrosSocio = (e, index) => {
        dispatch({
            type: SET_NROS_SOCIO,
            payload: {
                index: index,
                value: e.target.value
            }
        })
    }

    const enviarError = (err) => {
        var error=null;
        if(err.response!==undefined) error = err.response;
        if(err.data!==undefined) error = err;

        const alerta = {
            msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
            categoria: error !== undefined ? error.data.type : "error",
        }
        dispatch({
            type: MENSAJE_ERROR,
            payload: alerta,
        })
    }

    const resetearMensajeComodines = () => {
        dispatch({
            type: RESETEAR_MENSAJE,
        })
    }

    const resetearCargando = () => {
        dispatch({
            type: RESETEAR_CARGANDO,
        })
    }

    return (
        <comodinesContext.Provider
            value={{
                comodines: state.comodines,
                mensajeComodines: state.mensajeComodines,
                cargandoComodines: state.cargandoComodines,
                nrosSocio: state.nrosSocio,
                obtenerComodines,
                setNrosSocio,
                canjearComodin,
                resetearMensajeComodines,
                resetearCargando
            }}> 
            {props.children}
        </comodinesContext.Provider>
    )
}

export default ComodinesState;


